<template>
  <v-stepper v-model="stepperCount" class="elevation-0">
    <v-stepper-header class="elevation-0">
      <v-stepper-step :complete="stepperCount > 1" step="1"> </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="stepperCount > 2" step="2"> </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3"> </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <!-- Enter email form -->
      <v-stepper-content step="1" class="my-12">
        <p>
          Enter your email
        </p>
        <v-form v-model="isFormOneValid">
          <v-text-field
            label="Email"
            v-model="email"
            data-testid="email"
            outlined
            clearable
            :rules="[validateEmail]"
          >
          </v-text-field>
        </v-form>
        <v-btn
          color="primary"
          @click="onSendCode"
          data-testid="send-code"
          large
          elevation="6"
          :disabled="!isFormOneValid"
        >
          Send Code
        </v-btn>
        <br />
        <p class="mt-4">
          <router-link :to="{ name: 'Login' }" data-testid="login-instead">
            Login instead
          </router-link>
        </p>
      </v-stepper-content>
      <!-- Email confirmation code form -->
      <v-stepper-content step="2">
        <p>
          Reset your password
        </p>
        <p>
          We've sent you a verification code to your email account.
        </p>
        <v-form v-model="isFormTwoValid">
          <v-text-field
            label="Verifcation code"
            v-model="verificationCode"
            data-testid="code"
            outlined
            clearable
            :rules="[validateIsNumber]"
          >
          </v-text-field>
        </v-form>

        <v-btn
          text
          @click="stepperCount = 1"
          large
          elevation="6"
          class="ma-2"
          data-testid="back-2"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          @click="stepperCount = 3"
          large
          :disabled="!isFormTwoValid"
          elevation="6"
          data-testid="next-2"
        >
          Next
        </v-btn>
        <br />
        <v-btn
          @click="onSendCode"
          class="my-5"
          text
          small
          bottom
          data-testid="resend-code"
        >
          Resend code
        </v-btn>
      </v-stepper-content>
      <!-- Reset password form -->
      <v-stepper-content step="3" class="my-4">
        <p class="">
          Enter your new password
        </p>
        <v-form v-model="isFormThreeValid">
          <v-text-field
            v-model="newPassword"
            data-testid="password"
            label="New Password"
            :rules="[validatePassword]"
            outlined
            clearable
            :append-icon="newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (newPasswordVisible = !newPasswordVisible)"
            :type="newPasswordVisible ? 'text' : 'password'"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            data-testid="confirm-password"
            label="Confirm Password"
            :rules="[
              validateSamePassword(this.newPassword, this.confirmPassword)
            ]"
            outlined
            clearable
            :append-icon="newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (newPasswordVisible = !newPasswordVisible)"
            :type="newPasswordVisible ? 'text' : 'password'"
          ></v-text-field>
          <v-btn
            text
            @click="stepperCount = 2"
            large
            elevation="6"
            class="ma-2"
            data-testid="back-3"
          >
            Back
          </v-btn>
          <v-btn
            color="primary"
            @click="onResetPassword"
            large
            :disabled="!isFormThreeValid"
            elevation="6"
            data-testid="reset"
          >
            Reset
          </v-btn>
        </v-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import UserPool from '@/plugins/UserPool'
import {
  validateIsNumber,
  validateSamePassword,
  validateEmail,
  validatePassword
} from '@/utilities/validationRules'
import { CognitoUser } from 'amazon-cognito-identity-js'

export default {
  name: 'ChangePassword',
  data() {
    return {
      user: null,
      showPassword: false,
      stepperCount: 1,
      email: '',
      verificationCode: '',
      newPassword: '',
      confirmPassword: '',
      newPasswordVisible: false,
      isFormOneValid: false,
      isFormTwoValid: false,
      isFormThreeValid: false
    }
  },
  methods: {
    validateIsNumber,
    validateSamePassword,
    validateEmail,
    validatePassword,
    onSendCode() {
      this.user = new CognitoUser({
        Username: this.email,
        Pool: UserPool
      })
      this.user.forgotPassword({
        onSuccess: (data) => {
          console.log('onSuccess', data) // DEBUG
        },
        onFailure: (err) => {
          console.log('error', err) // DEBUG
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        },
        inputVerificationCode: (data) => {
          console.log('Input code', data) // DEBUG
          const notification = {
            type: 'success',
            message: 'Confirmation code is sent'
          }
          this.$store.dispatch('addNotification', notification)
          this.stepperCount = 2
        }
      })
    },
    onResetPassword() {
      this.user.confirmPassword(this.verificationCode, this.newPassword, {
        onSuccess: (data) => {
          console.log('Password changed!', data)
          this.$router.push({ name: 'Login' })
        },
        onFailure: (err) => {
          console.log('FAIL', err)
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        }
      })
    }
  }
}
</script>
