<template>
  <div class="bg">
    <LoginCard>
      <ForgotPassword />
    </LoginCard>
  </div>
</template>

<script>
import LoginCard from '@/components/login/LoginCard';
import ForgotPassword from '@/components/login/ForgotPassword';

export default {
  name: 'Register',
  components: {
    LoginCard,
    ForgotPassword,
  },
};
</script>

<style scoped>
.bg {
  background-color: #0c5c7e;
  height: 100%;
}
</style>
